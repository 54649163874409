import React, { useState, useRef } from "react";
import { api } from "../../../api";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";

const ForgotPassword = ({ handleModal, setScreenId }) => {
  const [otpStatus, setOtpStatus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { mutateAsync: GetOtp } = useMutation(api.forgotPassword);
  const { mutateAsync: ResetPassword } = useMutation(api.resetPassword);

  const backdropRef = useRef(null);

  const { register, handleSubmit } = useForm();

  const handleBackdropClick = (event) => {
    // Check if the backdrop element was clicked (not its children)
    if (event.target === backdropRef.current) {
      handleModal();
    }
  };

  const handleOTP = async (values) => {
    const { email } = values;
    toast.loading("Sending o.t.p...");
    try {
      const data = await GetOtp({ email });
      toast.remove();
      toast.success(data?.message, { duration: 50000 });
      setOtpStatus(true);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
    // console.log(email);
  };

  const handleForgotPassword = async (values) => {
    toast.loading("Sending o.t.p...");
    try {
      const data = await ResetPassword(values);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        setScreenId(0);
      }, 500);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };

  if (!otpStatus) {
    return (
      <div
        className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center"
        ref={backdropRef}
        onClick={handleBackdropClick}
      >
        <div
          className="rounded-lg w-full mx-auto p-7 bg-white"
          style={{ maxWidth: "500px" }}
        >
          <div className="w-full flex items-center justify-end">
            <button onClick={handleModal}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <form className="w-full" onSubmit={handleSubmit(handleOTP)}>
            <p>Enter your Email</p>
            <input
              className="block text-baseFont w-full border border-slate-300 p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
              type="email"
              {...register("email", {
                required: true,
              })}
              placeholder="email..."
            />
            <input
              id="submit"
              type="submit"
              value="Get O.T.P"
              className="hover:text-400 text-baseFont w-full mt-4 mx-auto rounded-md cursor-pointer bg-armyGreen text-white py-2"
            />
          </form>
        </div>
      </div>
    );
  }
  return (
    <div
      className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center"
      ref={backdropRef}
      onClick={handleBackdropClick}
    >
      <div
        className="rounded-lg w-full mx-auto p-7 bg-white"
        style={{ maxWidth: "500px" }}
      >
        <div className="w-full flex items-center justify-end">
          <button onClick={handleModal}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <form onSubmit={handleSubmit(handleForgotPassword)}>
          <p>Enter your Email</p>
          <input
            className="block text-baseFont w-full border border-slate-300 p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
            type="email"
            disabled={true}
            {...register("email", {
              required: true,
            })}
            placeholder="email..."
          />
          <p>Enter your O.T.P</p>
          <input
            className="block text-baseFont w-full border border-slate-300 p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
            type="text"
            {...register("otp", {
              required: true,
            })}
            placeholder="O.T.P"
          />

          <p>Enter your Password</p>
          <div className="mb-4 relative w-full">
            <input
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: true,
              })}
              className="block text-baseFont w-full border border-slate-300 p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
            />
            <span
              className="absolute right-2 text-slate-400 cursor-pointer"
              style={{ top: "8px" }}
              onClick={(e) => handleShowPassword(e)}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </div>
          <p>Enter your Password</p>
          <div className="mb-4 relative w-full">
            <input
              type={showConfirmPassword ? "text" : "password"}
              {...register("password_confirmation", {
                required: true,
              })}
              className="block text-baseFont w-full border border-slate-300 p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
            />
            <span
              className="absolute right-2 text-slate-400 cursor-pointer"
              style={{ top: "8px" }}
              onClick={(e) => handleShowConfirmPassword(e)}
            >
              {showConfirmPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </div>
          <button
            type="submit"
            style={{ fontSize: "12px" }}
            className="border border-customGreen text-white w-full py-2 bg-armyGreen rounded-md"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
