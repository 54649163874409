import Switch from "@themesberg/react-bootstrap/lib/esm/Switch";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Swal from "sweetalert2";
import CustomDropDown from "../../components/CustomDrpdown";
import Jumbotron from "../../components/Jumbotron";
import { useCustomToast } from "../../components/customtoast/CustomToastProvider";
import Footer from "../../layout/Footer";
import UpdatedNavbar from "../../layout/UpdateNavbar";
import { api } from "./../../api/index";
import ScaleLoader_ from "./../../components/ScaleLoader";
import Application from "./Application";
import Search from "./components/Search";

const JobApply = ({ listings, setListings }) => {
  
  const [searchParams] = useSearchParams();
  const campaign_id = searchParams.get('campaign-id');
  const affiliate_id = searchParams.get('affiliate-id');
  const [ roles, setRoles ] = useState([])
  const [ multipleRoles, setMultipleRoles ] = useState([])
  const {id}  = useParams()
  const [newUser, setNewUser] = useState(false);
  const [modal, setModal] = useState(false);
  const isAuth = parseInt(sessionStorage.getItem('is-authenticated'));
  const customToast = useCustomToast();

  const { data: campaign, isLoading } = useQuery(["get single job listing"],  () => {
    return api.getSingleJobListing(id)
}, {
   onSuccess: data => {       
      const pipelineRoles = [...data?.data?.pipelines.map( pipeline => {
        return {...pipeline, status: false}
     })]
     setRoles(pipelineRoles)      
     setMultipleRoles( pipelineRoles?.map( pipeline => {
         return { label: pipeline.role.name , value: pipeline.role.id }
     }).slice(0,1))
   }
} );

  const submitAffiliateMutation = useMutation(api.submitAffiliate);

  useEffect(() => {
    if (affiliate_id !== null && campaign_id !== null) {
      submitAffiliateMutation.mutate({
        campaignId: campaign_id,
        affiliateSlug: affiliate_id,
      });
    }
  }, []);

  var options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    localeMatcher: "best fit",
    timeZone: "Africa/Lagos", // Set the time zone to Nigeria
  };

  const timeAgo = (date) => {
    const currentDate = new Date();
    const timestamp = date.getTime();
    const currentTimestamp = currentDate.getTime();
    const timeDifference = currentTimestamp - timestamp;

    // Define time units in milliseconds
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;
    const week = 7 * day;
    const month = 30 * day;
    const year = 365 * day;

    if (timeDifference < minute) {
      return "Just now";
    } else if (timeDifference < hour) {
      return Math.floor(timeDifference / minute) + "m ago";
    } else if (timeDifference < day) {
      return Math.floor(timeDifference / hour) + "h ago";
    } else if (timeDifference < week) {
      return Math.floor(timeDifference / day) + "d ago";
    } else if (timeDifference < month) {
      return Math.floor(timeDifference / week) + "w ago";
    } else if (timeDifference < year) {
      return Math.floor(timeDifference / month) + "mo ago";
    } else {
      return Math.floor(timeDifference / year) + "y ago";
    }
  };

  const navigate = useNavigate();

  const links_ = [
    {
      path: "/",
      title: "Home",
      slug: "home",
    },
    {
      path: "/job-listings",
      title: "Job Listings",
      slug: "job-listings",
    },
    {
      path: `/job-listings/apply/${id}`,
      title: "Apply",
      slug: "job-details",
    },
  ];

  const encodeUrl = (url) => encodeURIComponent(url);

  const applyForRoleMutation = useMutation(api.ApplyForJob, {
    onSuccess: (data) => {
      toast.remove();     
      toast.success(data?.message);
      customToast.feedback(data?.message)
      setTimeout(() => navigate("/job-listings?feedback-status=true"), 100);
    },
    onError: (error) => {
      toast.remove();
      toast.error(error.response.data.error);
    },
    onMutate: () => {
      toast.loading("loading...");
    },
  });

  const saveJobInABookmarkMutaion = useMutation(api.saveJobInABookmark, {
    onSuccess: (data) => {
      toast.remove();
      toast.success("Bookmark saved.");
    },
    onError: (error) => {
      toast.remove();
      toast.error(error.response.data.error);
    },
    onMutate: () => {
      toast.loading("loading...");
    },
  });

  const handleLogout = async () => {
    try {
      // toast.loading("Logging you out...");
      await api.logout();
      // toast.remove();
      // toast.success(data?.message);
      sessionStorage.setItem("is-authenticated", "0");
    } catch ({ response }) {
      if (response.status === 401) {
        sessionStorage.setItem("is-authenticated", "0");
      } else {
        toast.error(response?.data?.message);
      }
      // toast.remove();
    }
  };

  const handleRegister = () => {
    handleLogout().then(() => {
      setModal(!modal);
      setNewUser(true);
    });

    // handleLogout();
  };

  const applyForARole = () => {
    if (!isAuth) {
      setModal(!modal);
      return sessionStorage.setItem(
        "application_data",
        JSON.stringify({
          id: campaign_id,
          affiliateSlug: affiliate_id === null ? "" : affiliate_id,
          roles_selected: multipleRoles?.map((role) => role?.value),
        })
      );
    } else {
      const close_date = new Date(campaign?.data?.end_date).getTime();
      if (!(new Date().getTime() < close_date)) {
        toast.error(`
                    Sorry, application closed on ${new Date(
                      campaign?.data?.end_date
                    ).toLocaleDateString()}
                `);
      } else if (
        campaign?.data?.role_selection === 1 &&
        !multipleRoles?.length
      ) {
        toast.error("Please select at least one role");
      } else {
        setModal(!modal);
        //save application info to session storage and use same after profile updateoae
        return sessionStorage.setItem(
          "application_data",
          JSON.stringify({
            id: campaign_id,
            affiliateSlug: affiliate_id === null ? "" : affiliate_id,
            roles_selected: multipleRoles?.map((role) => role?.value),
          })
        );

        // applyForRoleMutation.mutate({
        //   id: campaign_id,
        //   affiliateSlug: affiliate_id === null ? "" : affiliate_id,
        //   roles_selected: multipleRoles?.map((role) => role?.value),
        // });
      }
    }
  };

  const savedJobInABookmark = () => {
    if (!parseInt(sessionStorage.getItem("is-authenticated"))) {
      navigate("/login");
    } else {
      saveJobInABookmarkMutaion.mutate(campaign_id);
    }
  };

  const updateRoleStatus = (single_role) => {
    setRoles([
      ...roles.map((role) => {
        if (single_role?.id === role?.id) {
          return { ...role, status: !role.status };
        } else {
          return { ...role };
        }
      }),
    ]);
  };

  if (campaign_id === null || id === "null" || id === null) {
    return <Navigate to={"/job-listings"} />;
  } else if (isLoading) return <ScaleLoader_ />;
  else {
    return (
      <div className="px-1">
        {modal && (
          <Application
            applyForRoleMutation={applyForRoleMutation}
            newUser={newUser}
            handleModal={() => {
              setNewUser(false);
              setModal(!modal);
            }}
          />
        )}
        <UpdatedNavbar />
        <Jumbotron links={links_} active={"job-details"} />
        <div className="md:flex justify-between mt-4 px-md-4">
          <h5 className="mb-[12px] md:mb-0 md:mt-5">Job Details</h5>
          <Search setListings={setListings} />
        </div>
        <section className='mt-0  mx-2 mb-5 p-[8px] md:py-8 border md:flex justify-between border-gray-200 rounded-lg'>
             <div className='w-full md:w-[65%] md:pl-5'>
                  <h5 className='text-2xl font-semibold mt-2'>
                     {campaign?.data?.title}
                  </h5>
                  <p className='text-md mt-2 font-semibold'>
                       <span className='font-normal bg-blue-100 text-blue-500 py-2 px-4 text-xs'>
                        {campaign?.data?.client?.name} 
                       </span>
                      <button className='ml-5 py-1 font-normal text-xs bi bi-alarm bg-gray-100 px-3'>
                       &nbsp;{timeAgo(new Date(campaign?.data?.created_at !== null && campaign?.data?.created_at ))}
                     </button>
                  </p>                   
                  <p className='text-sm mt-2 font-semibold'>
                     Application Status: &nbsp;  
                     {   new Date().getTime() < new Date(campaign?.data?.end_date).getTime() ?
                        <span className='text-sm font-normal text-armyGreen'>Open</span>
                        :
                        <span className='text-sm font-normal text-red-500'>Closed</span>
                     }
                  </p>
                  <p className='text-sm mt-2 font-semibold'>
                     Closing Date: &nbsp;                      
                        <span className='text-sm font-normal'>
                            {`${new Date(campaign?.data?.end_date).toLocaleDateString('en-NG',options)}`}
                        </span>                                             
                  </p>
                  <p className='text-sm mt-2'>                     
                    <div className='inline-block' dangerouslySetInnerHTML={{__html: campaign?.data?.description}} />
                  </p>
             </div>
             <div className='w-full mt-[10px] md:mt-0 rounded-lg  md:mt-0 md:w-[35%]'>                 
                <div className=' bg-gray-100 h-auto py-5 px-4'>
                  { campaign?.data?.role_selection === 1 ?
                  <section>
                   <h5 className='capitalize text-sm border-b border-gray-300 mb-3 pb-1'>
                      Select Roles you are interested in.
                   </h5>
                   <CustomDropDown
                    options={
                      roles?.length ?
                        roles?.map( pipeline => {
                            return { label: pipeline.role.name , value: pipeline.role.id }
                        })
                      : []                      
                     }
                     value={multipleRoles}
                     width={'w-full'}
                     multiSelect={true}
                     onChange={ (values) => setMultipleRoles(values) }
                   />             
                </section> : null }
                <p className='text-sm mb-4 pl-3 pt-2'> Apply or save in a bookmark to apply later.</p>
                <div className='md:flex'>
                     <button 
                        onClick={applyForARole}
                        className='button-md text-xs mr-3'> 
                           { isAuth ? 'Apply' : 'Login to apply' }
                      </button>                      
                        <button 
                          onClick={savedJobInABookmark}
                          className='button-md text-xs' style={{background: '#008'}}>                            
                           { isAuth ? 'Save in bookmark ' : 'Login to save in a bookmark ' }
                        </button>                       
                </div>                 
                <div className="mt-3">
                     <span className="text-sm">New User?</span>
                    {/* <Link to={'/register'} className='text-sm text-blue-500 ml-3 py-2  underline rounded-full'>
                        Register
                    </Link> */}
                  <button
                    onClick={handleRegister}
                    className="text-sm text-blue-500 ml-1 py-2 underline rounded-full"
                  >
                    Register
                  </button>{" "}
                   and update your profile to apply
               </div>
                <div className='social-media text-md mt-4 md:ml-5'>
                  <span className='text-sm'>SHARE ON</span> : &nbsp; &nbsp;
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeUrl(document.URL)}`}
                  target="_blank" rel="noopener noreferrer"
                  className='bi text-2xl bi-facebook me-3 text-blue-500'></a>
                  <a href= {`https://api.whatsapp.com/send?text=${encodeUrl(campaign?.data?.name)}%20${encodeUrl(document.URL)}`} 
                  target="_blank" rel="noopener noreferrer"
                  className='bi text-2xl bi-whatsapp me-3'></a>
                  <a href={`https://twitter.com/intent/tweet?url=${encodeUrl(document.URL)}&text=${ encodeUrl(campaign?.data?.name) }`}
                  target="_blank" rel="noopener noreferrer"
                  className='bi text-2xl bi-twitter me-3'></a>
                  <a href={`https://www.linkedin.com/shareArticle?url=${encodeUrl(document.URL)}&title=${encodeUrl(campaign?.data?.name) }`}
                  target="_blank" rel="noopener noreferrer"
                  className='bi text-2xl bi-linkedin me-3'></a>                            
               </div>
             </div>
            </div>
        </section>
        <Footer />
      </div>
    );
  }
};

export default JobApply;
