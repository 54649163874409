import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import logo from "../../images/assets/TestAssessify_logo.png";
import { api } from "../../api";

function EmailVerification() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
 

  const handleResetPassword = async (e) => {
    e.preventDefault();
    let payload = {     
      email:email, 
      otp:otp,
    };

    try {
      toast.loading("Loading...");
      const res = await api.emailVerification(payload);
      toast.remove();
      toast.success(res.message);
        navigate("/profile");
    } catch (error) {
      toast.remove();
     toast.error(error.response.data.message);
    }
  };
  return (
    <div className="bg-white p-10">
      <div className="w-full pb-3 h-26 bg-inherit sticky top-0 cursor-pointer">
        <div className="h-full">
          <img
            src={logo}
            alt="Test Assessify"
            className="w-56 h-full object-contain"
          />
        </div>
      </div>

      <div className="container relative grid place-items-center mt-20 2xl:mt-0 2xl:fixed 2xl:h-screen ">
        <div className="bg-cover bg-no-repeat  bg-hero-img w-full  md:w-[600px] lg:w-[900px] rounded-xl p-10  shadow-2xl">
          <div className="my-2 shadow-2xl rounded-[8px] bg-white lg:w-1/2 p-2 py-10">
            <div className="flex flex-col w-full">
              <div className="flex flex-col justify-center px-4 pt-8 my-auto md:justify-start md:pt-0  lg:px-4">
                <p className="text-3xl text-darkBlue pt-4 mb-2 font-semibold">
                  Email Verification
                </p>
                <p className="text-base font-normal text-slate-5">
                  Please verify your email before proceeding to login
                </p>
                <form className="pt-3 md:pt-4" onSubmit={handleResetPassword}>
                  <div className="flex flex-col pt-4 mb-4">
                    <div className="flex relative ">                       
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="flex-1 appearance-none rounded-r-lg border border-gray-300 w-full py-2  px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent"
                        placeholder="Enter your email address"
                        value={sessionStorage.getItem('user-email')}                        
                        readOnly={true}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col pt-4 mb-4">
                    <div className="flex relative ">
                      {/* <span className=" inline-flex rounded-l-lg items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                        <svg
                          width="15"
                          height="15"
                          fill="currentColor"
                          viewBox="0 0 1792 1792"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                        </svg>
                      </span> */}
                      <input
                        type="otp"
                        id="otp"
                        name="otp"
                        className="flex-1 appearance-none rounded-lg border border-gray-300 w-full py-2  px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent"
                        placeholder="Enter your otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </div>
                  </div>                  

                  <button
                    type="submit"
                    className="w-full px-4 py-2 rounded-lg text-base font-semibold text-center text-white transition duration-200 ease-in bg-[#88a848] shadow-md  focus:outline-none focus:ring-2"
                  >
                    <span className="w-full text-md">Verify</span>
                  </button>
                </form>
                <div className="py-4  text-center">
                  <p className="text-md">
                    Click here to proceed to &nbsp;
                    <Link to="/login" className="font-semibold underline">
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
