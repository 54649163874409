import React, {useState} from 'react'
import toast from 'react-hot-toast'
import ScaleLoader_ from '../../../components/ScaleLoader'
import { api } from '../../../api/index'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

const Search = ({setListings}) => {

  const [ search, setSearch ] = useState('')
  const navigate = useNavigate()

  const handleChange = event => {
      event.preventDefault()
      setSearch(event.target.value)
  }

  const handleSearch = event => {
     event.preventDefault()
      const recentFilter = (sessionStorage.getItem('job-listings-filters'))
      let updatedFilter;
      if(recentFilter) updatedFilter = {...JSON.parse(recentFilter), search }
      else updatedFilter = { sort: 'asc', pageNo: 1 , per_page: 10, search }
      sessionStorage.setItem('job-listings-filters', JSON.stringify(updatedFilter))
     if(search !== ''){
        navigate('/job-listings?search=' + search )
     }
  }

  return (
    <>  
    <div class="mb-3 h-full w-[300px] md:w-[400px]">
    <div class="relative mb-4 flex w-full flex-wrap items-stretch">
      <input
        type="search"
        class="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-white bg-clip-padding px-3 py-[0.6rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-armyGreen focus:text-neutral-700 focus:shadow-0 focus:outline-none "
        placeholder="Try... e.g front end developer"
        aria-label="Search"
        aria-describedby="button-addon1" 
        onChange={ handleChange }
        value={search}
        />
  
      <button
        class="relative z-[2] flex items-center rounded-r bg-armyGreen px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-armyGreen hover:shadow-lg focus:bg-armyGreen focus:shadow-lg focus:outline-none focus:ring-0 active:bg-armyGreen active:shadow-lg"
        type="button"
        id="button-addon1"
        data-te-ripple-init
        data-te-ripple-color="light"
        onClick={handleSearch}
        >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="h-5 w-5">
          <path
            fill-rule="evenodd"
            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
            clip-rule="evenodd" />
        </svg>
      </button>
    </div>
    </div>
    </>
  )
}

export default Search