import React, { useState, useEffect } from "react";
import BioData from "./Biodata/BioData";
import Education from "./Education/Education";
import FileUpload from "./FileUpload/FileUpload";
import Experience from "./Experience/Experience";
import Nysc from "./Nysc/Nysc";
import toast from "react-hot-toast";
import { api } from "../../api";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EmailVerificationModal from "./EmailVerificationModal";
import { useCustomToast } from "../../components/customtoast/CustomToastProvider";
import UserProfileProgress from "./UserProfileProgress";

const Profile = () => {
  const customToast = useCustomToast();

  const navigate = useNavigate();
  const { uid1, uid2 } = useParams();
  const location = useLocation();
  const currentRoute = location.pathname;

  const user = JSON.parse(sessionStorage.getItem("user"));
  const isApply = sessionStorage.getItem("noapply");

  const [haveExperience, setHaveExperience] = useState(false);
  const [noExperience, setNoExperience] = useState(false);
  const [haveDoneNysc, setHaveDoneNysc] = useState(false);
  const [noNysc, setNoNysc] = useState(false);
  const [openVericationModal, setOpenVericationModal] = useState(false);
  const [profileStatus, setProfileStatus] = useState(0);

  useEffect( () => {
     if(!parseInt(sessionStorage.getItem('is-authenticated'))){
        navigate('/login')
     }
  }, [] )

  let LinkPayload = {
    uid1: uid1,
    uid2: uid2,
  };

  // if (currentRoute === `/profile/${uid1}/${uid2}`) {
  //   const { isLoading, data, isError, error } = useQuery(
  //     ["direct-profile"],
  //     async () => {
  //       const res = await api.directLinkToProfile(LinkPayload);
  //       sessionStorage.setItem("userToken", res.data.token);
  //       sessionStorage.setItem("user", JSON.stringify(res?.data));

  //       return res;
  //     }
  //   );
  // }

  const { data: experiences, refetch } = useQuery(
    "get-experiences",
    api.getUserExperienceList
  );
  // const { data: educations, refetch: refetchEducation } = useQuery(
  //   "get-educations",
  //   api.getUserEducationList
  // );
  const { data: nyscData, refetch: refetchNysc } = useQuery(
    "get-nysc-data",
    api.getNysc
  );

  const { data, refetch: refetchStatus } = useQuery(
    ["get-status"],
    async () => {
      const res = await api.getProfileStatus();
      setProfileStatus(res.data);
      return res;
    }
  );

  const handleHaveExperience = () => {
    setHaveExperience(!haveExperience);
    setNoExperience(false);
  };

  const handleNoExperience = () => {
    setNoExperience(!noExperience);
    setHaveExperience(false);
  };
  const handleHaveDoneNysc = () => {
    setHaveDoneNysc(!haveDoneNysc);
    setNoNysc(false);
  };

  const handleNoNysc = () => {
    setNoNysc(!noNysc);
    setHaveDoneNysc(false);
  };

  const handleApply = async () => {
    let payload = {
      slug: user.slug || sessionStorage.getItem("slug"),
      referral: user.referral || sessionStorage.getItem("referral"),
    };
    try {
      // if (user.email_verified_at === null) {
      //   setOpenVericationModal(true)
      //   return
      // }
      toast.loading("loading...", {
        style: {
          container: "custom-toast-container",
        },
      });
      const res = await api.jobApplication(payload);
      toast.remove();
      customToast.success(res.message);
      if (res.success === true) {
        navigate("/candidate/dashboard");
      }
      sessionStorage.setItem("noapply", "no-application");
      sessionStorage.removeItem("slug");
      sessionStorage.removeItem("referral");
    } catch (error) {
      toast.remove();
      customToast.error(error.response.data.error);
    }
  };

  return (
    <div className="max-w-[1368px] bg-white mt-20 p-4 md:p-16 rounded-lg">
      {openVericationModal && (
        <EmailVerificationModal closeModal={setOpenVericationModal} />
      )}
      {profileStatus < "80%" && (
        <h1 className="font-semibold text-[#F35B24] text-2xl py-4">
          Update your profile to complete application.
        </h1>
      )}      
      <div className="w-full md:flex gap-x-10">
        <div className="w-full md:w-2/5 relative max-w-xs p-6 overflow-hidden bg-[#F2F3F7]  rounded-xl h-fit mb-10 md:mb-0 ">
          <UserProfileProgress profileStatus={profileStatus} />
        </div>
        <div className="w-full md:w-3/5">
          <div className="">
            <div className="">
              <BioData refetchStatus={refetchStatus} />
            </div>
            <Education
              refetchStatus={refetchStatus}
              // educations={educations}
              // refetchEducation={refetchEducation}
              // key={educations}
            />
            {/* <FileUpload /> */}
            {experiences?.data?.length === 0 ? (
              <div className="my-10">
                <div className=" flex items-center gap-x-4 mb-10">
                  <div className="w-1/3">
                    <p className="text-base font-medium">
                      Do you have any work Experience?
                    </p>
                  </div>
                  <div className="w-2/3 flex gap-x-6 items-center">
                    {" "}
                    <div className="">
                      <label
                        htmlFor="experience"
                        className="inline-flex items-center"
                      >
                        <input
                          type="checkbox"
                          id="experience"
                          checked={haveExperience}
                          onChange={handleHaveExperience}
                          className="form-checkbox h-5 w-5 text-armyGreen"
                        />
                        <span className="ml-2 text-sm">Yes</span>
                      </label>
                    </div>
                    <div className="">
                      <label
                        htmlFor="no-experience"
                        className="inline-flex items-center"
                      >
                        <input
                          type="checkbox"
                          id="no-experience"
                          checked={noExperience}
                          onChange={handleNoExperience}
                          className="form-checkbox h-5 w-5 text-armyGreen"
                        />
                        <span className="ml-2 text-sm">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                {haveExperience && (
                  <Experience
                    experiences={experiences}
                    refetchStatus={refetchStatus}
                    refetch={refetch}
                    key={experiences}
                  />
                )}
                {noExperience && (
                  <div className="text-armyGreen text-center text-base">
                    {" "}
                    Continue your application
                  </div>
                )}
              </div>
            ) : (
              <Experience
                experiences={experiences}
                refetch={refetch}
                refetchStatus={refetchStatus}
                key={experiences}
              />
            )}

            <div className="my-10">
              {nyscData?.data === null ? (
                <div>
                  <div className="flex items-center gap-x-4 mb-10">
                    <div className="w-1/3">
                      {" "}
                      <p className="text-base font-medium">
                        Are you done with Nysc?
                      </p>
                    </div>
                    <div className="w-2/3 flex gap-x-6 items-center">
                      <div className="">
                        <label
                          htmlFor="experience"
                          className="inline-flex items-center"
                        >
                          <input
                            type="checkbox"
                            id="experience"
                            checked={haveDoneNysc}
                            onChange={handleHaveDoneNysc}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 text-sm">Yes</span>
                        </label>
                      </div>
                      <div className="">
                        <label
                          htmlFor="no-experience"
                          className="inline-flex items-center"
                        >
                          <input
                            type="checkbox"
                            id="no-experience"
                            checked={noNysc}
                            onChange={handleNoNysc}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 text-sm">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {haveDoneNysc && (
                    <Nysc
                      key={nyscData}
                      nyscData={nyscData}
                      refetchNysc={refetchNysc}
                      refetchStatus={refetchStatus}
                    />
                  )}
                  {noNysc && (
                    <div className="text-armyGreen text-center text-base">
                      {" "}
                      Continue your application
                    </div>
                  )}
                </div>
              ) : (
                <Nysc
                  nyscData={nyscData}
                  refetchNysc={refetchNysc}
                  refetchStatus={refetchStatus}
                  key={nyscData}
                />
              )}
            </div>

            <div>
              {/* && educations?.data?.length >= 1 && */}
              {!isApply && user?.campaign_title !== null && (
                // (educations?.data?.length >= 1 && experiences?.data?.length >= 1 ? (
                <div className="flex justify-center">
                  <button
                    onClick={handleApply}
                    className="bg-armyGreen text-white px-12 py-2 font-medium text-xl rounded-lg"
                  >
                    Submit Application
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
