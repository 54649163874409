
const Header = ({title, titleStyles, fontSize}) => {
  return (
    <h1 style={{ ...titleStyles, ...commonStyles, fontSize: fontSize ?? "44px" }}>{title}</h1>
  )
}

export default Header

const commonStyles = {
  fontWeight: 'bold',
}