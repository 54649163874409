import React, { createContext, useContext, useState } from "react";
import CustomToast from "./CustomToast";

const CustomToastContext = createContext();

export function CustomToastProvider({ children }) {
  const [toast, setToast] = useState(null);

  const success = (message) => {
    setToast({ type: "success", message });
  };

  const feedback = (message) => {
    setToast({ type: "feedback", message });
  };

  const error = (message) => {
    setToast({ type: "error", message });
  };

  const dismiss = () => {
    setToast(null);
  };

 return (
    <CustomToastContext.Provider value={{ success, feedback, error, dismiss }}>
      <div>
        {children}   
        {toast && <CustomToast toast={toast} setToast={setToast} />}
      </div>
    </CustomToastContext.Provider>
  );  
}

export function useCustomToast() {
  return useContext(CustomToastContext);
}
