import React from 'react'
import Card from './Card'
import candiateCardImage from  '../../../images/assets/Rectangle 8.png'
import relevantDataImage from '../../../images/assets/Rectangle 9.png'
import simulationImage from '../../../images/assets/Rectangle 10.png'

const WhyChooseUs = () => {
  return (
    <div className='why-choose-us bg-green-100 py-5 px-3 mt-3 mb-5'>
         <h5 className='text-center text-xl mt-2'> Why Choose Us</h5>
         <div className='grid grid-cols-1 md:grid-cols-3 gap-4 md:px-8 mt-3'>
            <Card 
               title={'Candidates'}
                text={`Candidates feel they have been treated
                       fairly and leave the experience as fans of
                        your brand even if they do not get the job.
                 `}
                className={'bg-white pb-3 rounded-lg md:w-[80%]'}
                img={candiateCardImage}
            />
            <Card 
               title={'Candidates'}
                text={`Candidates feel they have been treated
                       fairly and leave the experience as fans of
                        your brand even if they do not get the job.
                 `}
                className={'bg-white pb-3 rounded-lg md:w-[80%]'}
                img={relevantDataImage}
            />
            <Card 
               title={'Candidates'}
                text={`Candidates feel they have been treated
                       fairly and leave the experience as fans of
                        your brand even if they do not get the job.
                 `}
                className={'bg-white pb-3 rounded-lg md:w-[80%]'}
                img={simulationImage}
            />
         </div>
    </div>
  )
}

export default WhyChooseUs