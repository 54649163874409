import React from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { useEffect } from "react";
import JobListings from "./views/JobListings/JobListings";
import JobApply from "./views/JobApply/JobApply";
import Login from "./views/Login/Login";
import ForCompanies from "./views/ForCompanies/ForCompanies";
import AboutUs from "./views/AboutUs/AboutUs";
import { compareSync } from "bcryptjs";
import BookMark from './views/BookMarks/BookMark';
import FAQs from "./views/FAQs/FAQs";
import Home from './views/Home/Home';
import HomeNew from './views/HomeNew'
import NotFound from "./notFound";
import Register from "./views/Signup/Register";
import EmailVerification from './views/Signup/EmailVerification'
import Profile from "./views/Profile/Profile";

const Routing = () => {

  const location = useLocation()
  const accountType = sessionStorage.getItem("account-type")
  const [listings, setListings ] = React.useState([])

  useEffect( 
    () => {
          window.scrollTo({top:0 , behavior:'instant'})
            if(accountType){
                  //compare hashed admin role string
              if (!compareSync("candidate", accountType) && !compareSync("admin", accountType)) {
                sessionStorage.setItem('is-authenticated', '0')
            }              
            } 
  } , [location.pathname])
 
  return (
        <Routes>
          <Route exact path="/" element={<HomeNew />} />
          <Route 
                 path="/job-listings" 
                element={<JobListings listings={listings} setListings={setListings} />}
            />
          <Route 
                 path="job-listings/apply/:id" 
                 element={<JobApply listings={listings} setListings={setListings} />}
           />
          <Route path="/login" element = {<Login />} />
          <Route path="/register" element={<Register /> } />
          <Route path="/for-companies" element={<ForCompanies /> } />
          <Route
                path="/email-verification"
                element={ <EmailVerification/>}
          />
          <Route
                path="/profile"
                element={ <Profile/>}
          />
          <Route path="/about-us" element={<AboutUs /> } />
          <Route path="/frequently-asked-questions" element={<FAQs /> } />
          <Route path="/bookmarks" element={
               accountType ?
               compareSync("candidate", accountType) ?
                <BookMark /> : <Navigate to={'/login'} />
                : null
            } />
            <Route path="*" element={<NotFound />} />
        </Routes>
  );
};
export default Routing;
