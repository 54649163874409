import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { api } from "../../../api";
import Input from "../../../components/Global/Input";
import Logo from "../../../components/Logo";

const Register = ({ setScreenId, handleModal }) => {
  const methods = useForm();
  const {
    watch,
    formState: { errors },
  } = methods;
  const location = useLocation();

  // Extract the slug and referral from url
  const slug = location.pathname.split("/")[2];
  const referral = new URLSearchParams(location.search).get("referral");

  sessionStorage.setItem("slug", slug || null);
  sessionStorage.setItem("referral", referral || null);

  let payload = {
    slug: location.pathname.split("/")[2],
    referral: new URLSearchParams(location.search).get("referral"),
  };

  const resetForm = () => {
    methods.reset();
    methods.setValue("email", ""); // Reset password field
    methods.setValue("first_name", ""); // Reset password field
    methods.setValue("last_name", ""); // Reset password field
    methods.setValue("password", ""); // Reset password field
    methods.setValue("confirm_password", ""); // Reset confirm_password field
  };

  const Register = methods.handleSubmit(async (data) => {
    try {
      toast.loading("Loading...");
      const res = await api.signUp({
        ...data,
        slug: null,
        referral: payload.referral,
      });
      toast.remove();
      sessionStorage.setItem("userToken", res.data.token);
      sessionStorage.setItem("user", JSON.stringify(res?.data));
      setScreenId(2);
      toast.success(res?.message);
      sessionStorage.setItem("user-email", data?.email);
      sessionStorage.setItem("user-password", data?.password);
      resetForm();
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  });

  return (
    <div
      className="rounded-lg w-full mx-auto p-0 md:p-4 overflow-y-scroll bg-white max-w-[96%] md:max-w-[600px]"      
    >
      <div className="w-full flex items-center justify-end pr-3">
        <button onClick={handleModal}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
      <div className="rounded-[8px] bg-white w-full px-4">
        <div className="flex flex-col w-full">
          <div className="flex flex-col justify-center px-4 pt-1 my-auto md:justify-start md:pt-0 lg:px-4">
            {/* <div className="2xl:mb-10">
              <p className="text-2xl text-darkBlue pt-4 mb-6 font-semibold text-center">
                Welcome to Test Assesify
              </p>
              <p className="text-lg font-medium text-darkBlue mb-2">
                How To Apply For Jobs On Test Assessify
              </p>
              <div>
                <p className="mb-2 text-sm">
                  {" "}
                  <span className="font-medium text-darkBlue text-sm">
                    Step 1 :
                  </span>{" "}
                  Create a profile or log in to your existing account
                </p>
                <p className="mb-2 text-sm">
                  <span className="font-medium text-darkBlue text-sm">
                    Step 2 :
                  </span>{" "}
                  Apply for a role and submit your application.
                </p>
                <p className="mb-2 text-sm">
                  <span className="font-medium text-darkBlue text-sm">
                    Step 3 :
                  </span>{" "}
                  Get notified and track your progress.
                </p>
              </div>
              <p className="text-xl font-medium text-darkBlue mt-2">
                Create Account
              </p>
            </div> */}
            <div className="w-full flex items-center justify-between">
              {" "}
              <h1 className="text-lg ">Register Account</h1>
              <Logo height="38" />
            </div>

            <FormProvider {...methods}>
              <form className="" onSubmit={(e) => e.preventDefault}>
                <div className="">
                  <div className="flex relative pb-2 group mb-1">
                    <Input
                      name="email"
                      // value={values.email}
                      // onChange={handleInputChange}
                      isIcon
                      placeholder="Enter your email"
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      }}
                      className="custom-input text-sm"
                      type="email"
                      ref={(ref) => methods.register(ref)}
                    />
                  </div>
                </div>
                <div className="mb-1">
                  <Input
                    // id="first_name"
                    name="first_name"
                    placeholder="Enter your first name"
                    rules={{ required: "First Name is required" }}
                    type="text"
                    ref={(ref) => methods.register(ref)}
                  />
                </div>
                <div className="mb-1">
                  <Input
                    // id="last_name"
                    name="last_name"
                    // value={values.last_name || ""}
                    placeholder="Enter your last name"
                    // onChange={handleInputChange}
                    rules={{ required: "Last Name is required" }}
                    type="text"
                    ref={(ref) => methods.register(ref)}
                  />
                </div>
                <div className="mb-1">
                  <Input
                    name="password"
                    placeholder="Enter  password"
                    rules={{ required: "Password is required" }}
                    type="password"
                    ref={(ref) => methods.register(ref)}
                  />
                </div>
                <div className="mb-1">
                  <Input
                    name="password_confirmation"
                    placeholder="Confirm password"
                    rules={{
                      required: "Password is required",
                      validate: (value) =>
                        value === watch("password") || "Passwords do not match",
                    }}
                    type="password"
                    ref={(ref) => methods.register(ref)}
                  />
                </div>
                {/* {errors.confirm_password && (
          <p className="text-red-500 mt-1">{errors.confirm_password.message}</p>
        )} */}
                <div className="sm:flex mt-3 justify-between items-center">
                  <button
                    type="submit"
                    onClick={Register}
                    className="w-full sm:w-fit text-center px-12 py-2 mb-1 rounded-lg text-base font-semibold  text-white transition duration-200 ease-in bg-[#88a848] shadow-md active:border-none active:outline-none focus:border-none focus:outline-none "
                  >
                    <span className="text-sm 2xl:text-md">Sign Up</span>
                  </button>
                  {/* <div className="py-2 text-center">
                    <p className="text-md">
                      Already have an account? &nbsp;
                      <button
                        type="button"
                        onClick={() => setScreenId(0)}
                        className="font-semibold underline text-primary"
                      >
                        Login
                      </button>
                    </p>
                  </div> */}
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
