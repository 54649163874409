import React from 'react'

const BillingCalculator = ({ credits, setCredits, setPrice, creditPerDollar, handleSetActivePlan}) => {

  const handleCreditChange = event => {
     const credits = parseFloat(event.target.value)
     setCredits( credits )
     setPrice( credits * creditPerDollar )
  }

  return (
    <div className='mt-3 ml-[30px] md:ml-3'>
       <div className='md:flex items-center justify-center'>
           <div className='w-[90%] md:w-[400px] mt-[25px] md:mt-3'>
             <span>  Assessment credits </span>
            <div className="relative md:mb-4 mt-2 flex flex-wrap items-stretch">
                <span
                    className="flex bi bi-calculator text-xl items-center whitespace-nowrap rounded-l border border-solid border-gray-200 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-gray-900"
                    ></span
                >
                <span
                    className="flex bi bi-cash-coin text-xl items-center whitespace-nowrap border border-x-0 border-solid border-gray-200 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-gray-900"
                    ></span
                >
                <input
                    type="number"
                    value={credits}
                    onChange={handleCreditChange}
                    className="relative m-0 -ml-px block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-gray-200 bg-transparent bg-clip-padding px-4 py-2 text-lg font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-green-500 focus:text-neutral-700 focus:outline-none" />
            </div>
           </div>

           <div className='text-center mx-8 text-2xl bi bi-activity mt-4'></div>

           <div className='w-[90%] md:w-[200px] mt-[0px] md:mt-5 md:mt-0'>
             <span>  Price </span>
            <div className="relative md:mb-4 mt-2 flex flex-wrap items-stretch">
                <span
                    className="flex text-xl items-center whitespace-nowrap rounded-l border border-solid border-gray-200 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-gray-900"
                    >$</span
                >                                
                <input
                    type="number"
                    disabled= { true }
                    readOnly = {true}
                    value={ credits * creditPerDollar }
                    className="relative bg-gray-100 m-0 -ml-px block w-[50px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-clip-padding px-4 py-2 text-lg font-normal leading-[1.6] text-gray-900 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-green-500 focus:text-neutral-700 focus:outline-none" />
            </div>
           </div>

           <div className='pt-[18px] md:pt-8'>
              <button 
               className='ml-5  py-2 px-12 bg-[#140049] text-white text-sm rounded-lg'
               data-te-toggle="modal"
                data-te-target="#exampleModal"
                data-te-ripple-init
                data-te-ripple-color="light"
                onClick={ () => handleSetActivePlan(credits,credits * creditPerDollar ) }
               >
                     Top Up 
              </button>
           </div>

       </div>
    </div>
  )
}

export default BillingCalculator