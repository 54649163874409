import axios from "axios";
import baseUrl from "../url";

const client = axios.create({
  baseURL: baseUrl,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

client.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem("userToken");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      sessionStorage.setItem("is-authenticated", "0");
    }
    return Promise.reject(error);
  }
);

export const api = {
  /* *********************************************************************************************** */
  //LOGIN
  login: (payload) =>
    client.post("/auth/login", { ...payload }).then(({ data }) => data),
  /* *********************************************************************************************** */
  //LOGout
  logout: () => client.post("/auth/logout").then(({ data }) => data),
  /* *********************************************************************************************** */
  // get job listings
  getJobListings: (payload) =>
    client
      .get(
        `/listings?per_page=${payload.per_page}&page=${payload.currentPage}${
          payload?.search ? "&search=" + payload?.search : ""
        }${
           payload?.sort ? "&sort=" + payload?.sort : ""
         }${
           payload?.location_id ? "&location_id=" + payload?.location_id : ""
         }${
          payload?.function_id ? "&function_id=" + payload?.function_id : ""
         }${
          payload?.industry_id ? "&industry_id=" + payload?.industry_id : ""
         }`
      )
      .then(({ data }) => data),
  /* *********************************************************************************************** */
  // get single job details
  getSingleJobListing: (slug) =>
    client.get(`/listings/${slug}`).then(({ data }) => data),
  /* *********************************************************************************************** */
  // apply for job
  ApplyForJob: (payload) =>
    client
      .post(
        `/listings/${payload.id}`,
        payload?.affiliateSlug
          ? {
              affiliate_link_slug: payload.affiliateSlug,
              roles_selected: payload?.roles_selected,
            }
          : {
              roles_selected: payload?.roles_selected,
            }
      )
      .then(({ data }) => data),

  resetPassword: (payload) =>
    client
      .post(`/auth/reset-password`, { ...payload })
      .then(({ data }) => data),

  forgotPassword: (payload) =>
    client
      .post(`/auth/forgot-password`, {
        ...payload,
      })
      .then(({ data }) => data),

  verifyCampaignData: (payload) =>
    client
      .post(`/applications/verify-data`, { ...payload })
      .then(({ data }) => data),

  // SIGN UP OR REGISTER CANDIDATE
  signUp: (payload) =>
    client
      .post(`/auth/register?account_type=candidate`, { ...payload })
      .then(({ data }) => data),

  /* *********************************************************************************************** */
  // apply for job
  saveJobInABookmark: (slug) =>
    client.post(`/listings/${slug}/bookmarks`).then(({ data }) => data),
  /* *********************************************************************************************** */
  // get bookmarks
  getBookmarks: (pageNo) =>
    client
      .get(`/listings/bookmarks${pageNo ? "?page=" + pageNo : ""}`)
      .then(({ data }) => data),
  /* *********************************************************************************************** */
  // billing plans
  getPlans: () => client.get(`/plans`).then(({ data }) => data),
  /* *********************************************************************************************** */
  //  topup account
  topUpAccount: (payload) =>
    client
      .post(`/admin/billings/top-up`, { ...payload })
      .then(({ data }) => data),
  /* *********************************************************************************************** */
  // newsletter email
  newsLetterSubscribe: (payload) =>
    client
      .post(`/newsletter/subscribe`, { ...payload })
      .then(({ data }) => data),
  /* *********************************************************************************************** */
  submitAffiliate: (data) =>
    client
      .post(`/listings/${data.campaignId}/affiliates/${data.affiliateSlug}`)
      .then(({ data }) => data),
  /* *********************************************************************************************** */

  AddNyscData: (payload) =>
    client
      .post(`candidate/profile/nysc-data`, { ...payload })
      .then(({ data }) => data),

  getUserExperienceList: () =>
    client.get(`/candidate/profile/experience`).then(({ data }) => data),

  getNysc: () =>
    client.get(`/candidate/profile/nysc-data`).then(({ data }) => data),

  getProfileStatus: () =>
    client.get(`candidate/profile/status`).then(({ data }) => data),

  createUserExperience: (payload) =>
    client
      .post(`/candidate/profile/experience`, { ...payload })
      .then(({ data }) => data),

  updateUserExperience: (id, payload) =>
    client
      .put(`/candidate/profile/experience/${id}`, { id, ...payload })
      .then(({ data }) => data),

  getInstitutions: () => client.get(`/institutions`).then(({ data }) => data),

  getUserEducationList: () =>
    client.get(`/candidate/profile/education`).then(({ data }) => data),

  getCourses: () => client.get(`/courses`).then(({ data }) => data),
  getStates: () => client.get(`/states`).then(({ data }) => data),
  getDegrees: () => client.get(`/degrees`).then(({ data }) => data),

  getClassOfDegrees: () =>
    client.get(`/class-of-degrees`).then(({ data }) => data),

  verifyEmail: (payload) =>
    client.post("auth/email-verification", payload).then(({ data }) => data),

  resendOtp: (payload) =>
    client
      .post("auth/resend-verification-otp", payload)
      .then(({ data }) => data),

  getUserBiodata: () =>
    client.get(`/candidate/profile/bio-data`).then(({ data }) => data),

  updateUserBiodata: (payload) =>
    client
      .patch(`/candidate/profile/bio-data`, { ...payload })
      .then(({ data }) => data),

  createUserEducation: (payload) =>
    client
      .post(`/candidate/profile/education`, { ...payload })
      .then(({ data }) => data),

  updateUserEducation: (id, payload) =>
    client
      .put(`/candidate/profile/education/${id}`, { id, ...payload })
      .then(({ data }) => data),

  deleteUserEducation: (id) =>
    client
      .delete(`/candidate/profile/education/${id}`)
      .then(({ data }) => data),

  deleteUserExperience: (id) =>
    client
      .delete(`/candidate/profile/experience/${id}`)
      .then(({ data }) => data),

    getFeedbackQuestions: (payload) => client.get(`/feedbacks`, { ...payload }).then(({ data }) => data),

    sendFeedback: (payload) => client.post(`/feedbacks`, { ...payload }).then(({ data }) => data),
    
    getFunctions: () => client.get(`functions`).then(({ data }) => data ),
    getLocations: () => client.get(`locations`).then(({ data }) => data ),
    getIndustries: () => client.get(`industries`).then(({ data }) => data ),
};
