import React, {useState} from 'react';
import { Container } from "react-bootstrap";
import Header from "../../components/Header";
import Paragraph from "../../components/Paragraph";
import Search from './components/Search';
import Navbar from '../../layout/UpdateNavbar';
import { Select, Ripple, initTE } from "tw-elements";
import JobCard from './components/JobCard';
import Footer from '../../layout/Footer';
import { api } from './../../api/index';
import ScaleLoader_ from './../../components/ScaleLoader';
import { useQuery } from 'react-query';
import NoJob from './components/NoJob';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import FeedbackModal from './components/FeedbackModal';
import CustomDropdown from '../../components/CustomDrpdown';
import toast from 'react-hot-toast';
import Pagination from '../../components/Pagination/Pagination';

const JobListings = ({ listings, setListings }) => {

  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')
  const location_id = searchParams.get('location_id') 
  const function_id = searchParams.get('function_id')
  const industry_id = searchParams.get('industry_id') 
  const order_by = searchParams.get('order_by')
  const [openModal, setOpenModal] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const orderByOptions = [
    { value: 'asc', label: 'Most Recent (Asc)' },
    { value: 'desc', label: 'Most Recent (Desc)' },
   ]

  const { data, isLoading, isError, refetch} = useQuery(["get listings"],  () => {
      return api.getJobListings({
          pageNo: 1, per_page: perPage, search, location_id,
          industry_id, function_id, sort: order_by, currentPage,
        })
  }, {
    onSuccess: () => {
       toast.remove()
    },
  } );

  const { data: industries,isLoading: isIndustriesLoading,} = useQuery(["get industries"],  () => {
    return api.getIndustries()
  });

  const { data: jobTypes, isLoading: isJobTypesLoading,} = useQuery(["get job types"],  () => {
    return api.getFunctions()
  } );

  const { data: locations , isLoading: isLocationsLoading,} = useQuery(["get locations"],  () => {
     return api.getLocations()
   });

  React.useEffect( () => {     
    initTE({ Select, Ripple })
  }, [])

  React.useEffect( () =>  {
      refetch()
      toast.loading('Loading...')
  }, [ order_by, function_id, location_id, industry_id, perPage, currentPage])

  React.useEffect( () =>  {
      if(search === '' || search === null ){
         document.querySelector('button.search-btn')?.click()
      }
  }, [search])

  const openFeedbackModal = () => setOpenModal(true)

  const applyFilter = ( key, value, action = null ) => {
    let params = []
      for (const [key, value] of queryParams.entries()) {
        params.push({key, value})
      }
     
       if(!action){
          const paramIndex = params?.findIndex( param => param?.key === key )
          if(paramIndex === -1) params.push({key, value})
          else params[paramIndex] = {key, value} 
       }
       else {
         params = params.filter( param => {
           return param?.key !== key
         })
       }

      let newParam = ``
      params.forEach( (param, index) => {
          if(index === 0) newParam += `${param.key}=${param.value}`
          else newParam += `&${param.key}=${param.value}`
      })
    navigate(`/job-listings?${newParam}`)
  }

  if(isLoading) return <ScaleLoader_ />
  
  else if(isError){
       return <h5 className='text-center mt-[300px] text-md'>
                  Error! an unkown error occured.
              </h5>
  }

  return (
    <section>         
        <Navbar />
         { openModal && 
         <FeedbackModal 
            setOpenModal={setOpenModal}
            className={'w-full md:w-[800px] md:h-[560px] h-[500px] overflow-y-auto'}
            headerText={'Feedback'}
          />           
         }
         <button
          id='open-feedback-modal'
          className='bg-transparent w-0 h-0 p-0 m-0 hidden'
          onClick={openFeedbackModal}
         ></button>
       <div className="job-page-image md:px-5" style={{marginTop: 0}}>
        <Container>
          <section className='grid grid-cols-1 md:grid-cols-2  gap-3'>
            <div style={{ marginTop: "15%" }}>
              <Header
                titleStyles={HeaderStyling}
                title="Revolutionizing Your Job Search"
              />
              <Paragraph
                text="Discover opportunities, and the future of your career with us. "
                textStyles={textStyles}
              />
              <div className='mt-3'>
                <Paragraph
                    text="Key Word Search"
                    textStyles={{color:'#000'}}
                />
                <Search value = { search ? search : ''} refetch={refetch} applyFilter={applyFilter} />
              </div>
            </div>
            <div className='listing-banner-image hidden md:block md:ml-[150px] md:mt-8'>
            </div>
          </section>
        </Container>
      </div>
      <h5 className='pl-3 text-lg mt-5'> Available Jobs For You </h5>
        <section className='md:flex flex-wrap mx-2 py-5 rounded-lg mt-1 bg-gray-100 items-center pl-3 mb-4 md:px-8'>
        <h2 className='inline-block text-md mr-5 w-full mb-1'>Filter By: </h2>
             <div className='flex mt-[15px] ml-3 md:mt-0 items-center'>
                 <CustomDropdown
                    width={'w-[180px]'}
                    value={
                      ( order_by === undefined || order_by === null ) ?
                      {
                        label: 'Select', value: ''
                      } :
                      {
                        label: orderByOptions?.find( type => type?.value === order_by )?.label,
                        value: order_by 
                      }
                    }
                    onChange={ (value) => {
                      applyFilter('order_by', value?.value)
                    } }
                    options={orderByOptions}
                 />
                 {
                  order_by !== null &&
                  <button 
                    className='mx-2 rounded-full py-2 px-3 text-[10px] bg-white text-red-500 bi bi-x-lg'
                    onClick={ () => applyFilter('order_by', order_by, 'delete') }    
                 >
                </button>}
             </div>
             <div className='flex ml-3 mt-[15px] md:mt-0 items-center'>
                <h2 className='inline-block text-xs mr-2 bi bi-geo-alt'>&nbsp;Location </h2>
                <CustomDropdown 
                   width={'w-[180px]'}
                   value={
                     ( location_id === undefined || !location_id ) ?
                     {
                       label: 'Select', value: ''
                     } :
                     {
                       label: locations?.data?.find( location => location?.id === location_id )?.name,
                       value: location_id 
                     }
                   }
                   onChange={ (value) => {
                     applyFilter('location_id', value?.value)
                   } }
                   options={
                     !isLocationsLoading ?
                       [
                        ...locations?.data?.map( location => {
                         return { value: location?.id , label: location?.name }
                        })]
                     : []
                   }  
                   placeholder={'Select'}
                />
                 {
                  location_id !== null &&
                  <button 
                    className='mx-2 py-2 rounded-full px-3 text-[10px] bg-white text-red-500 bi bi-x-lg'
                    onClick={ () => applyFilter('location_id', location_id, 'delete') }    
                 >
                </button>}
             </div>
             <div className='flex ml-3 rounded-full mt-[15px] md:mt-0 items-center'>
                <h2 className='inline-block text-xs mr-2 bi bi-briefcase'>&nbsp;Job Type </h2>
                <CustomDropdown
                    width={'w-[180px]'}
                    value={
                      ( function_id === undefined || !function_id ) ?
                      {
                        label: 'Select', value: ''
                      } :
                      {
                        label: jobTypes?.data?.find( job_type => job_type?.id === function_id )?.name,
                        value: function_id 
                      }
                    }
                    onChange={ (value) => {
                      applyFilter('function_id', value?.value)
                    } }
                   options={
                    !isJobTypesLoading ?
                    [
                     ...jobTypes?.data?.map( jobType => {
                      return { value: jobType?.id , label: jobType?.name }
                     })]
                    : []
                   }  
                />
                 {
                  function_id !== null &&
                  <button 
                    className='mx-2 rounded-full py-2 px-3 text-[10px] bg-white text-red-500 bi bi-x-lg'
                    onClick={ () => applyFilter('function_id', function_id, 'delete') }    
                 >
                </button>}
             </div>
             <div className='flex ml-3 mt-[15px] md:mt-0 items-center'>
                <h2 className='inline-block text-xs mr-2 bi bi-building'>&nbsp;Industry </h2>
                <CustomDropdown 
                    width={'w-[180px]'}
                    value={
                      ( industry_id === undefined || !industry_id ) ?
                      {
                        label: 'Select', value: ''
                      } :
                      {
                        label: industries?.data?.find( industry => industry?.id === industry_id )?.name,
                        value: industry_id 
                      }
                    }
                    onChange={ (value) => {
                      applyFilter('industry_id', value?.value)
                    } }
                   options={
                    !isIndustriesLoading ?
                    [
                     ...industries?.data?.map( industry => {
                      return { value: industry?.id , label: industry?.name }
                     })]
                    : []
                   }  
                />
                 {
                  industry_id !== null &&
                  <button 
                    className='mx-2 rounded-full py-2 px-3 text-[10px] bg-white text-red-500 bi bi-x-lg'
                    onClick={ () => applyFilter('industry_id', industry_id, 'delete') }    
                 >
                </button>}
             </div>
      </section>  
      <section className='mb-5 px-[8px] xl:w-[90%] xl:mx-auto md:px-5'>
      {(data?.data?.data?.length && !isLoading ) ?
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-5'>
            { 
             data?.data?.data?.map( listing => {
                return(
                  <JobCard 
                    title={listing?.title}
                    listing={listing}
                    status = {new Date().getTime() < new Date(listing?.end_date).getTime()}
                    company={listing?.client?.name}
                    type={listing?.job_function?.name}
                    location={listing?.job_location?.name}
                    date = { new Date(listing?.created_at !== null && listing?.created_at ) }
                    link={`/job-listings/apply/${listing?.slug}?campaign-id=${listing?.id}`}
                    key={listing?.id}
                />
                )
            }) 
            }
        </div> :
         <NoJob />}
        { isLoading ? null :
          <Pagination 
            data={data?.data}
            pageNo={currentPage}
            perPage={perPage}
            setPageNo={setCurrentPage}
            setPerPage={setPerPage} 
        />}
      </section>
      <Footer />
    </section>
  )
}

const HeaderStyling = {
    color: "#19144D",
    // textAlign: 'center'
  };
  
  const textStyles = {
    color: "#19144D",
    fontSize: 26
  };
  
export default JobListings