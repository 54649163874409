import React, { useState, useEffect } from 'react'
import CustomDropdown from '../../../components/CustomDrpdown'
import { useMutation } from 'react-query'
import { api } from '../../../api/index'
import toast from 'react-hot-toast'
import ScaleLoader_ from '../../../components/ScaleLoader'

const Pagination = ({ jobs, setListings }) => {

 const [currentPage, setCurrentPage ] = useState(1)
 const [perPage, setPerPage] = useState(10)

 const getListingsMutation = useMutation(api.getBookmarks, {
    onSuccess: (data) => {
        toast.remove()
        setListings(data)
        window.scrollTo({top: 0 , behavior:'instant'})
    },
    onMutate: () => {
        toast.loading('Loading...')
    },
    onError: () => {
        toast.error('Error! an unknown error occured')
    }
 })
 
 const handlePagination = link => {
    const  url = link?.url
     if(url !== null ){
        const pageNo = url.slice(url.lastIndexOf('=') + 1, url.length)
        getListingsMutation.mutate(pageNo)
        setCurrentPage(pageNo)
     }
 }

  return (
    <> { getListingsMutation.isLoading ? <ScaleLoader_ />
        :
    <div className='md:flex justify-between md:px-5 mt-4'>
         <div>
            <nav aria-label="Page navigation example w-full">
                <ul style={{ whiteSpace: 'nowrap'}} class="list-style-none overflow-x-auto  block py-1 items-center overflow-x-auto w-full">
                   {jobs?.data?.links.map( (link, index) => {
                         if(( index === 0) || (index === jobs?.data?.links?.length - 1) ){
                           return <li 
                            key={link?.label}
                            className='inline-block'
                            onClick={ () => handlePagination(link)}
                             >
                             <a
                                className="pointer-events-none relative block rounded-full bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300 dark:text-neutral-400"
                                dangerouslySetInnerHTML={{ __html: link?.label }}
                              />
                           </li> 
                         }
                        else if(link?.active){
                           return(
                             <li aria-current="page" 
                                 className='inline-block' 
                                 onClick={ () => handlePagination(link)}
                             >
                                <a
                                    class="relative block rounded-full bg-primary-100 px-3 py-1.5 text-sm font-medium text-primary-700 transition-all duration-300"
                                    href="#!"
                                    > {link?.label}
                                    <span
                                    class="absolute -m-px h-px w-px overflow-hidden whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]"
                                    >( {link?.label} )</span>
                                </a>
                             </li>
                           )
                        } 
                        else {
                            return(
                             <li className='inline-block' onClick={() => handlePagination(link)}>
                                <a
                                 className="relative block rounded-full bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100  dark:text-white dark:hover:bg-neutral-700 dark:hover:text-white"
                                  href="#!"
                                >{link?.label}</a>
                              </li>
                            )
                        }
                      })}
                   </ul>
                </nav>
            <div>
            </div>
        </div>
    </div>}
  </>
  )
}

export default Pagination