import React from 'react';
import Select from 'react-select';

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
      backgroundColor: state.isFocused ? '#8EAF55' : 'white',
      color: state.isFocused ? 'white' : 'black',
    }),
    control: (provided, state) => ({
        ...provided,
        border: state.isFocused && '2px solid #8EAF55',
        outline: state.isFocused && 'none', // Apply custom border style
        boxShadow: state.isFocused && '0 0 0 transparent',
      }),
  };

const CustomDropdown = ({ 
            width,value,onChange, options, defaultValue = 0,
             multiSelect = false, placeholder
  }) => {
  return (
     <div className={width ? width : 'w-[200px]'}>
         <Select
            options={options}
            className="custom-dropdown text-xs focus:border-armyGreen" // Apply custom CSS class
            classNamePrefix="dropdown"
            styles={customStyles}
            defaultValue={options[defaultValue]}
            value={value}
            onChange={onChange}
            isMulti={multiSelect}
            placeholder={placeholder}
         />
     </div>
  );
};

export default CustomDropdown;
