import LogoImg from '../images/assets/TestAssessify_logo.png';

const Logo = ({height, classes, width}) => {
  return (
    <img 
        src={LogoImg} alt='test_assessify'
        width={width ?? 200} height={height ?? 40}
        className={classes}
     />
  )
}

export default Logo