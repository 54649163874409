import React from 'react' 
import { Link } from "react-router-dom";

const links_ = [ 
    { 
        path : '/',
        title : 'Home',
        slug : 'home',
     },
     { 
        path : '/job-listings',
        title : 'Job Listings',
        slug : 'job-listings',
     },
     { 
        path : '/job-listings/apply/1',
        title : 'job details',
        slug : 'job-details',
     } 
 ]

const Jumbotron = ({ links =  links_  , active }) => {
  return (
    <nav className="text-gray-600 mt-3 text-sm">
        { links.map( ( link, index) => {
             if (index === links.length - 1 ) {
                return(
                    <> 
                      <Link
                        to={link.path}
                        className={`hover:underline ${ active === link.slug ? 'text-armyGreen underline' : ''} text-md`}
                        >
                        {" "}
                        { link.title }{" "}
                        </Link>{" "}                        
                    </>
                  )
             }
              return(
                <> 
                  <Link
                    to={link.path}
                    className={`${index === 0 ? 'bi bi-house-door ml-1' : ''} hover:underline ${ active === link.slug ? 'underline text-armyGreen' : ''} text-md`}
                    >
                    {" "}
                    {link.title}{" "}
                    </Link>{" "}
                    <span className='text-md bi bi-chevron-double-right'></span>
                </>
              )
        } ) }
    </nav>
  )
}

export default Jumbotron