import React, { useRef, useState } from "react";
import Login from "./modals/Login";
import Register from "./modals/Register";
import UpdateProfile from "./modals/UpdateProfile";
import VerifyEmail from "./modals/VerifyEmail";
import ForgotPassword from "./modals/ForgotPassword";
import { useEffect } from "react";
import { ScaleLoader } from "react-spinners";

const Application = ({ handleModal, newUser }) => {
  const backdropRef = useRef(null);
  const [screenId, setScreenId] = useState(0);
  const [isLoading, setisLoading] = useState(true);

  const isAuth = parseInt(sessionStorage.getItem("is-authenticated"));

  const handleBackdropClick = (event) => {
    // Check if the backdrop element was clicked (not its children)
    if (event.target === backdropRef.current) {
       //this feature is not required as was flagged as an issue by product manager.
      // handleModal();       
    }
  };

  //   console.log(isAuth);

  useEffect(() => {
    isAuth === 1 && setScreenId(3);
    setisLoading(false);
    sessionStorage.setItem('is-feedback', 'false')
  }, [isAuth, screenId]);

  useEffect(() => {
    newUser && setScreenId(1);
    setisLoading(false);
  }, [newUser]);

  const screens = [
    <Login setScreenId={setScreenId} handleModal={handleModal} />,
    <Register setScreenId={setScreenId} handleModal={handleModal} />,
    <VerifyEmail setScreenId={setScreenId} handleModal={handleModal} />,
    <UpdateProfile setScreenId={setScreenId} handleModal={handleModal} />,
    <ForgotPassword setScreenId={setScreenId} handleModal={handleModal} />,
  ];

  //   console.log("screens");

  //   console.log(screenId);

  if (isLoading) {
    return (
      <div
        className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center"
        ref={backdropRef}
      >
        <div
          className="w-full flex items-center justify-center gap-2"
          style={{ height: "300px" }}
        >
          <ScaleLoader size={150} color={"#f35b24"} />
        </div>
      </div>
    );
  }

  return (
    <div
      className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center"
    //  ref={backdropRef}
      //onClick={handleBackdropClick}
    >
      {screens && screens[screenId]}
    </div>
  );
};

export default Application;
