import Routing from "./Routing";
import { QueryClient, QueryClientProvider } from "react-query";
import toast, { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ChatwootWidget from "./components/ChatwootWidget/ChatwootWidget";
import { CustomToastProvider } from "./components/customtoast/CustomToastProvider";
const queryClient = new QueryClient();

const App = () => {
  return (
    <>
      <CustomToastProvider>
        <QueryClientProvider client={queryClient}>           
          <AnimatePresence>
            <Router>
              <Routing />
            </Router>
          </AnimatePresence>
         </QueryClientProvider>
        </CustomToastProvider>
        <div>
          <Toaster
            toastOptions={{
              style: {
                color: "#023047",
                fontFamily: "Rubik,sans-serif",
                fontSize: "12px",
              },
              success: {
                iconTheme: {
                  primary: "#fb8500",
                },
                style: {
                  color: "#023047",
                },
              },
            }}
          />          
        </div>
        <ChatwootWidget />      
    </>
  );
};

export default App;
