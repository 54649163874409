import React from 'react'

function EmailVerificationModal({closeModal}) {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex justify-center bg-black bg-opacity-50 p-10 z-50">
    <div className="">
      <div className="min-h-[12rem] md:w-[600px] bg-white rounded-[32px] flex flex-col justify-center p-6 md:p-10 relative">
        <h1 className="text-lg leading-6 font-medium text-[#F35B24] mb-4">
        Email not Verified !
        </h1>
       <p>You have to verify your email address to submit your application, Kindly check your mail for a verification link.</p>
        <div className='flex justify-between mt-6'>
            <button onClick={() => closeModal(false)} className='font-medium text-sm text-[#F35B24]'>
            Go Back
            </button>
            {/* <button onClick={() => closeModal(false)} className='font-medium text-sm text-primary'>
            Resend Link
            </button> */}
        
        </div>
      </div>
    </div>
  </div>
  )
}

export default EmailVerificationModal