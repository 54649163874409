import React, { useState } from "react";
import EditEducationModal from "./EditEducationModal";
import EditIcon from "../../../images/icons/edit-2.svg";
// import AddIcon from "../../../../assets/icons/add-square.svg";
import AddEducation from "./AddEducation";
// import { api } from "../../../../api";
import { api } from "../../../api";
import { useQuery } from "react-query";
import { FaGraduationCap } from "react-icons/fa";
import moment from "moment";
import { useCustomToast } from "../../../components/customtoast/CustomToastProvider";
// educations, refetchEducation;
function Education({ refetchStatus }) {
  const customToast = useCustomToast();
  const { data: educations, refetch: refetchEducation } = useQuery(
    "get-educations",
    api.getUserEducationList
  );
  const [openEducationModal, setOpenEducationModal] = useState(false);
  const [addEducationModal, setAddEducationModal] = useState(false);
  const [data, setData] = useState(educations?.data[0]);
  const { isLoading, data: institutions } = useQuery(
    "get-institution",
    api.getInstitutions
  );

  return (
    <div className="mb-3">
      <div className="mb-2">
        <div className="font-semibold text-xl text:darkBlue flex items-center gap-x-6 mb-6">
          Education
          <button
            className="cursor-pointer bg-armyGreen p-2 rounded-lg text-base text-white font-normal"
            onClick={() => setAddEducationModal(true)}
          >
            Add new education
            {/* <img src={AddIcon} alt="" /> */}
          </button>
        </div>
        <div>
          {openEducationModal && (
            <EditEducationModal
              closeModal={setOpenEducationModal}
              data={data}
              institutions={institutions}
              refetchEducation={refetchEducation}
              refetchStatus={refetchStatus}
            />
          )}
          {addEducationModal && (
            <AddEducation
              closeModal={setAddEducationModal}
              refetchEducation={refetchEducation}
              refetchStatus={refetchStatus}
            />
          )}
        </div>
        <div className="">
          {educations?.data.length === 0 && (
            <div className="text-center text-base font-medium  text-lightGrey">
              Please add education
            </div>
          )}
          {educations?.data.map((education, id) => (
            <div key={id}>
              <div
                className="mb-3 flex justify-between items-center"
                key={education.id}
              >
                <div className="flex gap-x-4">
                  <div className="h-16 bg-gray-300 p-2 flex justify-start rounded">
                    <FaGraduationCap className="text-[3rem]" />
                  </div>
                  <div className="g">
                    <div className="font-medium text-xl text-darkBlue">
                      {education.name}
                    </div>
                    <div className="font-normal text-lg">
                      {education.degree?.name}, {education.course?.title}
                    </div>
                    <div className="font-normal text-lg">
                      {" "}
                      <span>
                        {moment(education.start_date).format("yyy")}
                      </span>{" "}
                      - <span>{moment(education.end_date).format("yyy")}</span>
                    </div>
                    <div className="font-normal text-base">
                      {" "}
                      <span>Grade</span> :{" "}
                      <span>{education.class_of_degree?.name}</span>
                    </div>
                    {/* <div className="s">State : {education?.location}</div> */}
                  </div>
                </div>

                <div className="flex justify-end items-center gap-x-4">
                  <div
                    onClick={() => {
                      setData(education);
                      setOpenEducationModal(true);
                    }}
                    className="cursor-pointer"
                  >
                    <img src={EditIcon} alt="" />
                  </div>
                </div>
              </div>
              {/* <hr className="py-4" /> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Education;
