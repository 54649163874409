import React from 'react'
import EllipsImage from '../../../images/assets/Ellipse 6.png'
import personImage from '../../../images/assets/image 31.png'
import cylinderImage from '../../../images/assets/Group 106.png'


const TopTalentSection = () => {
  return (
    <div className='bg-[#FEDC8C] mt-0 py-5 mb-5'>
       <div className='md:flex justify-between'>
          <div className='md:w-[50%] flex items-center'>
              <img 
                     src={EllipsImage} alt="ellpise"
                     className='w-[100px] h-[150px]'
               />
              <img 
                     src={personImage} alt="ellpise"
                     className='ml-5 w-[280px] h-[400px]'
               />
               <img 
                     src={cylinderImage} alt="ellpise"
                     className='ml-5 w-[90px] h-[170px]'
               />
          </div>
          <div className='md:w-[50%] px-1'>
               <h5 className='text-2xl md:text-3xl font-semibold text-center mt-5'>
                  Top talent is priceless. We make finding them affordable and easier
               </h5>
               <p className='text-md md:w-[80%] mx-auto mt-3 text-center'>
                  A time-consuming screening process costs you a lot. 
                  Painful mis-hires cost you much, much more. With TestAssesify,
                   you avoid all of this and see a huge
                return on investment every single year. Start screening with us today on our free plan.
               </p>
               <a 
                 href='tel:+2347026585852'
                 className='button-md mt-5 text-center hover:text-white' style={{boxShadow: '0 0 0 transparent', width: 260 }}
                >
                  Talk To Us
               </a>
          </div>
       </div>
    </div>
  )
}

export default TopTalentSection