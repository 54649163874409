import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
const UserProfileProgress = ({ profileStatus }) => {
  const status = profileStatus.profile;
  const profileCompletionPercentage =
    profileStatus?.profile_completion_percentage ?? 0;

  return (
    <div>
      <div className="">
        <div className="flex gap-x-5 items-center">
          <div className="w-28 h-28 mt-8 mb-4">
            <CircularProgressbar
              value={parseInt(profileCompletionPercentage)}
              text={`${profileCompletionPercentage}`}
              counterClockwise
              strokeWidth={8} // Adjust the strokeWidth to control the thickness of the progress bar
              styles={{
                root: {},
                path: {
                  // Customize the progress bar color
                  stroke: `#87AA4B`,
                },
                trail: {
                  // Customize the trail color (background of the progress bar)
                  stroke: "#413659",
                },
                text: {
                  // Customize the text appearance
                  fill: "#413659",
                  fontSize: "20px",
                  fontWeight: "bold",
                },
              }}
            />
          </div>
          <div className="">
            <span className="text-[#413659] font-bold text-xl">
              Profile Status
            </span>
          </div>
        </div>
        <div className="mb-6">
          {profileStatus?.profile_completion_percentage === "100%" ? (
            <div className="flex flex-col ">
              <p className="font-semibold text-xl text-primary">Well done !</p>
            </div>
          ) : (
            <h3 className="font-medium">
              Complete your profile to Submit your application
            </h3>
          )}
        </div>
        <div className="">
          <div className="flex gap-x-6 items-center my-3">
            <div className="font-semibold text-lg w-1/2">Bio Data</div>
            <div className="w-1/2 text-primary">
              {status?.bio_data === 40 ? (
                <IoMdCheckmarkCircleOutline className="w-6 h-6" />
              ) : null}
            </div>
          </div>
          <div className="flex gap-x-6 items-center my-3">
            <div className="font-semibold text-lg w-1/2">Education</div>
            <div className="w-1/2 text-primary">
              {status?.education_data === 40 ? (
                <IoMdCheckmarkCircleOutline className="w-6 h-6" />
              ) : null}
            </div>
          </div>
          <div className="flex gap-x-6 items-center my-3">
            <div className="font-semibold text-lg w-1/2">Experience</div>
            <div className="w-1/2 text-primary">
              {status?.experience_data === 10 ? (
                <IoMdCheckmarkCircleOutline className="w-6 h-6" />
              ) : null}
            </div>
          </div>

          <div className="flex gap-x-6 items-center my-3">
            <div className="font-semibold text-lg w-1/2">Nysc</div>
            <div className="w-1/2 text-primary">
              {status?.nysc_data === 10 ? (
                <IoMdCheckmarkCircleOutline className="w-6 h-6" />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileProgress;
