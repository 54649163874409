import React from 'react'
import TopUpConfirmationModal from './TopUpConfirmationModal'
import EllipsImage from '../../../images/assets/hand-shake-image.PNG'
import BillingCalculator from './BillingCalculator'
import NewsLetterSubscribe from './../../AboutUs/components/NewsLetterSubscribe';

 const PlansSection = ({plans, isLoading, credits, setCredits, price, setPrice, creditPerDollar}) => {

    const handleSetActivePlan = (credits, price) => {
            setCredits(credits)
            setPrice(price)
    }

   return (
     <div className='mb-5'>
        <h5 className='text-xl text-center'>
            Premium Plans
        </h5>
        <p className='text-sm  mt-1 text-center'>  Maximize your potentials with tailored credits </p>
        <div className='card-wrapper mt-8 md:px-5 grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-8'>                          
             {
               !isLoading ?
                [...plans.sort((a, b) => a.credits - b.credits)]?.map( (plan,id) => {             
                    if(id===0) {
                        return(
                        <div className='shadow-lg rounded-lg  px-5 py-8'>
                        <h5 className='text-xl text-center'> Premium Plan </h5>
                        <p className='text-sm text-center mt-2'> Expand your potential </p>
                        <p className='text-sm mt-2 text-center'>  Get {plan.credits} assessment credit for </p>
                        <h5 className='text-5xl text-center font-bold my-3'> ${plan.price} </h5>
                        <div className='flex justify-center'>
                        <button 
                                className='py-2 px-12 bg-red-500 my-5 text-white text-sm rounded-lg'
                                data-te-toggle="modal"
                                data-te-target="#exampleModal"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                onClick={ () => handleSetActivePlan(
                                    parseInt(plan.credits), parseInt(plan.price)
                                ) }
                            >
                            Top Up
                        </button>
                        </div>
                        </div>
                        )
                    }
                    if(id === 1){
                        return (
                        <div className='shadow-lg rounded-lg bg-green-100 px-5 py-8'>
                            <h5 className='text-xl text-center'> Premium Plan </h5>
                            <p className='text-sm text-center mt-2'> Energize your experience with a burst </p>
                            <p className='text-sm mt-2 text-center'>  Get {plan.credits} assessment credit for </p>
                            <h5 className='text-5xl text-center font-bold my-3'> ${plan.price} </h5>
                            <div className='flex justify-center'>
                            <button 
                                className='py-2 px-12 bg-armyGreen my-5 text-white text-sm rounded-lg'
                                data-te-toggle="modal"
                                data-te-target="#exampleModal"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                onClick={ () => handleSetActivePlan(
                                    parseInt(plan.credits), parseInt(plan.price)
                                  ) }
                                >
                                Top Up
                            </button>
                            </div>
                        </div>
                        )
                    }
                    else if( id === 2 ){
                        return(
                        <div className='shadow-lg rounded-lg  px-5 py-8'>
                            <h5 className='text-xl text-center'> Premium Plan </h5>
                            <p className='text-sm text-center mt-2'> Power up and amplify your advantage </p>
                            <p className='text-sm mt-2 text-center'>  Get {plan.credits} assessment credit for </p>
                            <h5 className='text-5xl text-center font-bold my-3'> ${plan.price} </h5>
                            <div className='flex justify-center'>
                            <button 
                                className='py-2 px-12 bg-red-500 my-5 text-white text-sm rounded-lg'
                                data-te-toggle="modal"
                                data-te-target="#exampleModal"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                onClick={ () => handleSetActivePlan(
                                    parseInt(plan.credits), parseInt(plan.price)
                                ) }
                            >
                                Top Up
                            </button>
                            </div>
                        </div>)
                  }}) : null }
         </div>  
            <TopUpConfirmationModal
                credits = {credits}
                price = {price}
            />  
            <div className='mt-3'>
                <BillingCalculator
                   credits = {credits}
                   setCredits = {setCredits}
                   price = {price}
                   setPrice={setPrice}
                   handleSetActivePlan={handleSetActivePlan}
                   creditPerDollar={ creditPerDollar }
                />  
            </div>    
            <div className='bg-[#FEDC8C] mt-3 py-5 mb-5'>
                <h5 className='text-3xl text-center mb-3 font-semibold'>
                  Join the companies replacing CVs with TestAssessify
                </h5>
                <div className='md:flex justify-between'>
                <div className='md:w-[50%] px-1'>
                <h5 className='text-2xl md:text-2xl text-center mt-5'>
                  Top talent is priceless. We make finding them affordable and easier
               </h5>
               <p className='text-md md:w-[80%] mx-auto mt-3 text-center'>
                  A time-consuming screening process costs you a lot. 
                  Painful mis-hires cost you much, much more. With TestAssesify,
                   you avoid all of this and see a huge
                return on investment every single year. Start screening with us today on our free plan.
               </p>
               <a 
                 href='tel:+2347026585852'
                 className='button-md mt-5 text-center hover:text-white' style={{boxShadow: '0 0 0 transparent', width: 260 }}
                >
                  Talk To Us
               </a>
          </div>
          <div className='md:w-[50%] flex items-center justify-center'>
              <img 
                     src={EllipsImage} alt="ellpise"
                     className='w-full md:w-[360px] h-[240px]'
               />
          </div>
       </div>
    </div> 
    <NewsLetterSubscribe />
    </div>
  )
}

export default PlansSection